@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.VehicleCard {
  background-color: #fff;
  padding: 1.5rem;
  border-bottom: 1px solid #EBEBEB;
  transition: 0.2s;
}
.VehicleCard .car-header {
  color: #000;
}
.VehicleCard .car-image {
  display: flex;
  align-items: center;
}
.VehicleCard .car-image img {
  max-width: 128px;
}
.VehicleCard .car-image:hover + .car-description .car-header {
  color: #3B6E8F;
  text-decoration: none;
}
.VehicleCard .car-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.5rem;
}
.VehicleCard .car-description .car-header {
  cursor: pointer;
}
.VehicleCard .car-description .car-header:hover {
  color: #3B6E8F;
  text-decoration: none;
}
.VehicleCard .car-won-status {
  display: flex;
  justify-content: center;
  padding-left: 1.5rem;
}
.VehicleCard .car-won-status .won-status {
  display: flex;
  align-self: center;
  padding: 0.5rem 1.5rem;
  color: #459500;
  border: 2px solid #459500;
  text-transform: uppercase;
  font-weight: bold;
}
.VehicleCard .car-auction-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 1.5rem;
}
.VehicleCard .car-expires-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-left: 1.5rem;
}
.VehicleCard .car-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1rem;
  font-size: 2rem;
}
.VehicleCard .car-icons .vehicle-icon {
  margin-left: 1.5rem;
  width: 4rem;
  height: 2rem;
}
.VehicleCard .car-icons .dollar-icon {
  color: #C6C6C6;
}
.VehicleCard .car-icons .bud-gitt {
  color: #fff;
  font-size: 0.8rem;
  border: 1px solid #459500;
  border-radius: 5px;
  background-color: #459500;
  padding: 0 0.3rem;
  text-align: center;
}
.VehicleCard .car-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.VehicleCard .car-menu .toggle-button .menu-button {
  display: flex;
  flex-direction: column;
  height: 1.6rem;
  justify-content: space-between;
}
.VehicleCard .car-menu .toggle-button .menu-button .menu-button-circle {
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  background-color: #D8D8D8;
}
.VehicleCard .car-menu .toggle-button:hover .menu-button-circle {
  background-color: #3B6E8F;
}
.VehicleCard .car-menu .dropdown1.show .menu-button-circle {
  background-color: #3B6E8F;
}
.VehicleCard .created-amount-and-time, .VehicleCard .accepted-amount-and-time {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991.98px) {
  .VehicleCard .car-expires-in {
    padding-left: 0;
    padding-top: 1.5rem;
    align-items: flex-start;
  }
  .VehicleCard .car-description {
    padding-left: 0;
    padding-top: 1.5rem;
    align-items: flex-start;
  }
  .VehicleCard .car-icons {
    padding-left: 0;
    padding-top: 1.5rem;
  }
  .VehicleCard .car-menu {
    padding-top: 1.5rem;
  }
  .VehicleCard .car-auction-date {
    padding-left: 0;
    padding-top: 1.5rem;
    justify-content: flex-start;
  }
  .VehicleCard .created-amount-and-time, .VehicleCard .accepted-amount-and-time {
    padding-top: 1.5rem;
    justify-content: flex-start;
  }
}
