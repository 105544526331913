.Spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.Spinner > .img {
  background-image: url("./imgs/spinner.gif");
  background-position: center;
  background-size: contain;
}
.Spinner.big .img {
  width: 4rem;
  height: 4rem;
}
.Spinner.medium .img {
  width: 2rem;
  height: 2rem;
}
