@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.AdminWorkshopSignups .nav-links {
  display: flex;
}
.AdminWorkshopSignups .nav-links .nav-link {
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
}
.AdminWorkshopSignups .nav-links .nav-link:hover {
  color: #3B6E8F;
}
.AdminWorkshopSignups .nav-links .nav-link.active {
  color: #3B6E8F;
  border: 1px solid #3B6E8F;
}
.AdminWorkshopSignups .grid-container {
  position: relative;
  min-height: 8rem;
}
.AdminWorkshopSignups .grid-container .dropdown-filter .filter-toggle-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AdminWorkshopSignups .grid-container .dropdown-filter .filter-indicator {
  font-size: 0.5rem;
}
.AdminWorkshopSignups .grid-container .dropdown-filter .filter-indicator.is-active {
  color: #A0CF67;
}
.AdminWorkshopSignups .grid-container .dropdown-filter label {
  line-height: normal;
  margin-bottom: 0;
}
.AdminWorkshopSignups .loading-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
}
.AdminWorkshopSignups .popover {
  background-color: white;
  border: 1px silver solid;
  z-index: 99;
}
