@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.CustomRadio label {
  position: relative;
  padding-left: 2rem;
  display: flex;
  align-items: center;
}
.CustomRadio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.CustomRadio label .custom-radio {
  position: absolute;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.CustomRadio label .custom-radio:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #9A9A9A;
  border-radius: 50%;
}
.CustomRadio label .custom-radio:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #3B6E8F;
  border-radius: 50%;
}
.CustomRadio label:hover > .custom-radio:before {
  background-color: rgba(154, 154, 154, 0.1);
}
.CustomRadio label input:checked ~ .custom-radio:before {
  visibility: hidden;
}
.CustomRadio label input:checked ~ .custom-radio:after {
  visibility: visible;
}
