@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.WorkshopSignup {
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.WorkshopSignup .loading-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
}
.WorkshopSignup .blinking-message {
  text-align: center;
  font-weight: bold;
  font-size: smaller;
  display: flex;
  align-items: center;
  animation: blinkingText 1s infinite;
}
.WorkshopSignup .table-form-container {
  display: flex;
  width: 100%;
}
.WorkshopSignup .table-container {
  width: 55%;
}
.WorkshopSignup .form-container {
  width: 45%;
}
.WorkshopSignup table {
  margin: 2rem 0;
}
.WorkshopSignup table th, .WorkshopSignup table td {
  padding: 0.25rem 0.5rem !important;
  border: none;
}
.WorkshopSignup table hr {
  margin: 0 !important;
}
.WorkshopSignup .updating-signup-status-process-status {
  min-height: 2rem;
}
.WorkshopSignup form {
  background: #fff;
  margin: 2rem 0 2rem 2rem;
  padding: 4rem;
}

@keyframes blinkingText {
  0% {
    color: #3B6E8F;
  }
  50% {
    color: rgba(59, 110, 143, 0);
  }
  100% {
    color: #3B6E8F;
  }
}
