@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.TradeConditionCard {
  background-color: #fff;
  padding: 1.5rem;
  border-bottom: 1px solid #EBEBEB;
  transition: 0.2s;
}
.TradeConditionCard .accept-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.TradeConditionCard .accept-buttons .accept-button {
  min-width: 150px;
  display: flex;
  justify-content: center;
}
.TradeConditionCard .accept-buttons .accept-button button {
  display: flex;
  align-items: center;
}
.TradeConditionCard .accept-buttons .accept-button button.disabled {
  cursor: not-allowed;
}
.TradeConditionCard .accept-buttons .accept-button button .accept-button-icon-container {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.TradeConditionCard .accept-buttons .accept-button button .accept-button-icon-container .accept-button-icon {
  fill: #fff;
}
.TradeConditionCard .company-name {
  display: flex;
  align-items: center;
}
.TradeConditionCard .tc-view {
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
}
.TradeConditionCard .tc-status {
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .TradeConditionCard .tc-view {
    padding-left: 0;
    margin-top: 1.5rem;
  }
  .TradeConditionCard .tc-status {
    padding-left: 0;
    margin-top: 1.5rem;
  }
  .TradeConditionCard .accept-buttons {
    margin-top: 1.5rem;
    justify-content: flex-start;
  }
  .TradeConditionCard .accept-buttons .accept-button {
    min-width: 150px;
    justify-content: flex-start;
  }
}
