.CustomModal {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 4;
  top: 0;
}
.CustomModal-backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #222;
  z-index: 3;
}
.CustomModal-backdrop-translucent {
  background: none;
}
.CustomModal-backdrop-transparent {
  background: rgba(248, 248, 248, 0.6);
}
.CustomModal-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 4;
  position: relative;
  display: flex;
  padding: 2rem;
}
.CustomModal-content {
  border-radius: 4px;
  position: relative;
  margin: auto;
  background-color: #fff;
  opacity: 1;
}
