@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Gallery {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 3;
  top: 0;
}
.Gallery * {
  user-select: none;
}
.Gallery .gallery-curtain {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #222;
  z-index: 3;
}
.Gallery .gallery-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 4;
  position: relative;
  display: flex;
}
.Gallery .gallery-wrapper .gallery-image {
  width: 100%;
}
.Gallery .gallery-wrapper .gallery-close {
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 1rem;
  display: inline-block;
  transition: 0.1s;
  line-height: 1;
}
.Gallery .gallery-wrapper .gallery-close:hover {
  background: #333;
}
.Gallery .gallery-wrapper .control {
  cursor: pointer;
}
.Gallery .gallery-wrapper .control.control-left {
  margin-left: auto;
}
.Gallery .gallery-wrapper .control.control-left .arrow-right-icon-container {
  transform: rotate(180deg);
}
.Gallery .gallery-wrapper .control .arrow-right-icon-container {
  width: 3rem;
  height: 4rem;
  padding: 1rem;
  transition: 0.1s;
}
.Gallery .gallery-wrapper .control .arrow-right-icon-container:hover {
  background: #333;
}
.Gallery .gallery-wrapper .control .arrow-right-icon-container .arrow-right-icon {
  fill: #fff;
}
.Gallery .gallery-wrapper .thumb-image {
  height: 100px;
  padding: 5px;
  background-clip: content-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.1s;
}
.Gallery .gallery-wrapper .thumb-image:hover {
  opacity: 0.95;
}
.Gallery .gallery-wrapper .thumb-image.active {
  border: 2px solid #5ABEFF;
}
