@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.CustomCheckbox label {
  display: flex;
  align-items: center;
}
.CustomCheckbox label .label-text {
  overflow: hidden;
  overflow-wrap: break-word;
}
.CustomCheckbox label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.CustomCheckbox label .custom-checkbox {
  position: relative;
  margin-right: 0.5rem;
  min-width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.CustomCheckbox label .custom-checkbox:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #9A9A9A;
}
.CustomCheckbox label .custom-checkbox:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-image: url("./imgs/check.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3B6E8F;
}
.CustomCheckbox label:hover > .custom-checkbox:before {
  background-color: rgba(154, 154, 154, 0.1);
}
.CustomCheckbox label input:checked ~ .custom-checkbox:before {
  visibility: hidden;
}
.CustomCheckbox label input:checked ~ .custom-checkbox:after {
  visibility: visible;
}
