@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.InfoCard {
  padding: 0.9rem;
  margin-bottom: 10px;
  border-radius: 0.4rem;
}
.InfoCard .InfoCardIconWrapper {
  flex: 0 0 20px;
  height: 20px;
}

.InfoCard-success {
  background-color: rgb(237, 247, 237);
}
.InfoCard-success .InfoCardIconWrapper {
  fill: rgb(92, 182, 96);
}

.InfoCard-warning {
  background-color: rgba(232, 101, 0, 0.2);
}
.InfoCard-warning .InfoCardIconWrapper svg {
  stroke: #E86500;
}
