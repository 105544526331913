@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.SectionHeader {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: -15px;
  margin-right: -15px;
  background-size: cover;
  background-position: center;
}
.SectionHeader .color-layer {
  background-color: rgba(59, 110, 143, 0.8);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.SectionHeader .content {
  position: relative;
  z-index: 2;
  color: #fff;
}
