@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Slider {
  position: relative;
}
.Slider .ReactSlickSlider {
  height: 180px;
}
@media (max-width: 991.98px) {
  .Slider .ReactSlickSlider {
    height: 150px;
  }
}
@media (max-width: 767.98px) {
  .Slider .ReactSlickSlider {
    height: 100px;
  }
}
.Slider .ReactSlickSlider .slick-list, .Slider .ReactSlickSlider .slick-track, .Slider .ReactSlickSlider .slick-slide {
  height: 100%;
}
.Slider .ReactSlickSlider .slick-slide div {
  width: 100%;
  height: 100%;
}
.Slider .ReactSlickSlider .slider-image {
  background-clip: content-box;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: 0.1s;
}
.Slider .ReactSlickSlider .slider-image:hover {
  opacity: 0.95;
}
.Slider .slick-slide:not(:last-child) .slider-image {
  padding-right: 2px;
}
.Slider .control {
  opacity: 0;
  position: absolute;
  width: 3rem;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;
}
.Slider .control .arrow-right-icon-container {
  width: 0.7rem;
}
.Slider .control .arrow-right-icon-container .arrow-right-icon {
  fill: #fff;
}
.Slider:hover .control {
  opacity: 1;
}
.Slider .left-control {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.45));
  left: 0;
}
.Slider .left-control:hover {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.55));
}
.Slider .left-control .arrow-right-icon-container {
  margin-left: 0.8rem;
  margin-right: auto;
  transform: rotate(180deg);
}
.Slider .right-control {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.45));
  right: 0;
}
.Slider .right-control:hover {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.55));
}
.Slider .right-control .arrow-right-icon-container {
  margin-right: 0.8rem;
  margin-left: auto;
}
