@import './../../../styles/helpers';

.Page {
  $c: &;

  flex-grow: 1;
  position: relative;

  &#{$c}-with-padding {
    padding: 2rem !important;
  }
  &#{$c}-y-padding-small {
    padding: 1rem 2rem;
  }
  &.p0 {
    padding: 0;
  };
  &.not-fluid {
    max-width: 1200px;
    margin: auto;
  };
};