@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.LandingTop {
  background-image: url("./imgs/bg.jpg");
  background-size: cover;
  background-position: top;
  padding-bottom: 9rem !important;
  position: relative;
}
.LandingTop * {
  color: #fff;
}
.LandingTop .color-layer {
  background-color: rgba(59, 110, 143, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1199.98px) {
  .LandingTop .btn {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
