@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Filters {
  background-color: #fff;
}
.Filters h1, .Filters h2, .Filters h3, .Filters h4, .Filters h5, .Filters h6 {
  margin-bottom: 0;
}
.Filters .header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  padding-bottom: 0;
}
.Filters .header .clear-all {
  cursor: pointer;
  color: #3B6E8F;
}
.Filters .header .clear-all:hover {
  text-decoration: underline;
}
