@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.DropdownMenu .dropdown-menu-right {
  right: auto;
}
.DropdownMenu-toggle-button, .DropdownMenu-toggle-button:focus, .DropdownMenu-toggle-button:active {
  background-color: transparent !important;
  color: #3B6E8F !important;
  border: none !important;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
}
.DropdownMenu-toggle-button .caret-bottom-icon-container, .DropdownMenu-toggle-button:focus .caret-bottom-icon-container, .DropdownMenu-toggle-button:active .caret-bottom-icon-container {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.5rem;
}
.DropdownMenu-toggle-button .caret-bottom-icon-container .caret-bottom-icon, .DropdownMenu-toggle-button:focus .caret-bottom-icon-container .caret-bottom-icon, .DropdownMenu-toggle-button:active .caret-bottom-icon-container .caret-bottom-icon {
  fill: #3B6E8F;
}
.DropdownMenu-label {
  margin-right: 0.5rem;
}
.DropdownMenu-label, .DropdownMenu-label:focus, .DropdownMenu-label:active {
  color: #000 !important;
}
.DropdownMenu .dropdown-menu {
  min-width: initial;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.DropdownMenu .dropdown-menu .dropdown-item {
  padding: 10px;
  line-height: 1;
}
.DropdownMenu .dropdown-menu .dropdown-item:focus, .DropdownMenu .dropdown-menu .dropdown-item:active, .DropdownMenu .dropdown-menu .dropdown-item.active {
  color: inherit;
  background-color: #fff;
  outline: none;
}
.DropdownMenu .dropdown-menu .dropdown-item:hover {
  color: #3B6E8F;
  background-color: #fff;
}
