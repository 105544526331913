@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Button {
  position: relative;
  overflow: hidden;
}
.Button.disabled {
  cursor: not-allowed;
}
.Button.apply-white {
  color: #fff !important;
}
.Button.apply-white-on-hover:hover {
  color: #fff !important;
}
.Button.apply-color1-background-on-hover:hover, .Button.apply-color1-background-on-hover:active {
  background-color: #3B6E8F !important;
  outline: #3B6E8F !important;
  color: #fff !important;
}
.Button:active, .Button:focus {
  box-shadow: none !important;
}
.Button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
.Button.animate::after {
  animation: ripple 0.7s ease-out !important;
}
