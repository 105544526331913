@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.FaqBlockCategoryCard {
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #EBEBEB;
  cursor: pointer;
  transition: 0.2s;
  padding: 1rem;
}
.FaqBlockCategoryCard-header {
  text-align: center;
  transition: 0.2s;
  font-weight: bold;
}
.FaqBlockCategoryCard:hover {
  border-color: #3B6E8F;
  background-color: #3B6E8F;
}
.FaqBlockCategoryCard:hover .FaqBlockCategoryCard-header {
  color: #fff;
}
