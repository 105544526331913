@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.CustomInput {
  position: relative;
}
.CustomInput input, .CustomInput input:focus, .CustomInput textarea, .CustomInput textarea:focus {
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.375rem 0;
}
.CustomInput input, .CustomInput input:focus, .CustomInput textarea, .CustomInput textarea:focus {
  border-bottom: 2px solid #BCBCBC;
}
.CustomInput input::-ms-clear, .CustomInput textarea::-ms-clear {
  display: none;
}
.CustomInput label {
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 0.375rem;
  color: #4D4D4D;
  pointer-events: none;
  transition: 0.2s ease all;
  cursor: text;
}
.CustomInput .bar {
  position: relative;
  display: block;
}
.CustomInput .bar:before, .CustomInput .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #3B6E8F;
  transition: 0.2s ease all;
}
.CustomInput .bar:before {
  left: 50%;
}
.CustomInput .bar:after {
  right: 50%;
}
.CustomInput.has-error label {
  color: rgba(220, 52, 65, 0.6);
}
.CustomInput.has-error input:focus ~ label, .CustomInput.has-error textarea:focus ~ label {
  color: #DC3441;
}
.CustomInput.has-error.not-empty label {
  color: #DC3441;
}
.CustomInput.has-error input, .CustomInput.has-error textarea {
  border-bottom: 2px solid rgba(220, 52, 65, 0.6);
}
.CustomInput.has-error .bar:before, .CustomInput.has-error .bar:after {
  background: #DC3441;
}
.CustomInput input:focus ~ label, .CustomInput textarea:focus ~ label {
  top: -10px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #3B6E8F;
  cursor: default;
}
.CustomInput input:focus ~ .bar:before, .CustomInput textarea:focus ~ .bar:before {
  width: 50%;
}
.CustomInput input:focus ~ .bar-reduced-width:before, .CustomInput textarea:focus ~ .bar-reduced-width:before {
  width: -moz-calc(50% - 40px);
  width: -webkit-calc(50% - 40px);
  width: calc(50% - 40px);
}
.CustomInput input:focus ~ .bar:after, .CustomInput textarea:focus ~ .bar:after {
  width: 50%;
}
.CustomInput input:-webkit-autofill ~ label, .CustomInput textarea:-webkit-autofill ~ label {
  top: -10px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #3B6E8F;
  cursor: default;
}
.CustomInput input:-webkit-autofill ~ .bar:before, .CustomInput textarea:-webkit-autofill ~ .bar:before {
  width: 50%;
}
.CustomInput input:-webkit-autofill ~ .bar:after, .CustomInput textarea:-webkit-autofill ~ .bar:after {
  width: 50%;
}
.CustomInput.not-empty label {
  top: -10px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #3B6E8F;
  cursor: default;
}
.CustomInput.not-empty .bar:before {
  width: 50%;
}
.CustomInput.not-empty .bar-reduced-width:before {
  width: -moz-calc(50% - 40px);
  width: -webkit-calc(50% - 40px);
  width: calc(50% - 40px);
}
.CustomInput.not-empty .bar:after {
  width: 50%;
}

.show-password {
  position: absolute;
  right: 0px;
  top: 7px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 99999;
}

.reduced-width {
  width: -moz-calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
}
