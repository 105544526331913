@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.SearchInput {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.SearchInput .custom-search-input {
  position: relative;
  text-align: right;
  width: 100%;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.SearchInput .custom-search-input input, .SearchInput .custom-search-input input:focus {
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.375rem 0;
  background-color: transparent;
}
.SearchInput .custom-search-input input {
  display: inline-block;
  width: 5rem;
  transition: 0.2s ease all;
}
.SearchInput .custom-search-input ::-webkit-input-placeholder {
  color: #B4B4B4;
}
.SearchInput .custom-search-input ::-moz-placeholder {
  color: #B4B4B4;
}
.SearchInput .custom-search-input :-moz-placeholder {
  color: #B4B4B4;
}
.SearchInput .custom-search-input :-ms-input-placeholder {
  color: #B4B4B4;
}
.SearchInput .custom-search-input .bar {
  position: relative;
  display: block;
  width: 100%;
}
.SearchInput .custom-search-input .bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #3B6E8F;
  transition: 0.2s ease all;
}
.SearchInput .custom-search-input .bar:before {
  right: 0%;
}
.SearchInput .custom-search-input.not-empty .bar:before, .SearchInput .custom-search-input.not-empty input {
  width: 100%;
}
.SearchInput .custom-search-input input:focus ~ .bar:before {
  width: 100%;
}
.SearchInput .custom-search-input input:focus {
  width: 100%;
}
.SearchInput .search-icon-container {
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 1rem;
}
.SearchInput .search-icon-container .search-icon {
  fill: #3B6E8F;
  width: 222px;
}
.SearchInput label {
  padding: 0;
  margin: 0;
  line-height: 1;
}
