@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.AccessMatrix .vertical-text {
  writing-mode: vertical-rl;
}
.AccessMatrix .grid-container {
  text-align: center;
  overflow-x: auto;
  padding-left: 6rem;
  padding-right: 6rem;
}
.AccessMatrix .wreck-action-header {
  background: #fff;
  color: #3B6E8F;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEBEB;
}
.AccessMatrix .vehicle-type-header {
  writing-mode: vertical-rl;
  background: #fff;
  color: #3B6E8F;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F6F7;
  padding: 1rem 0;
  border-right: 1px solid #EBEBEB;
}
.AccessMatrix .workshop-type-header {
  background: #fff;
  color: #3B6E8F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid #EBEBEB;
}
.AccessMatrix .grid-cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.AccessMatrix .grid-cell .change-button {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  height: 2rem;
  width: 6rem;
  justify-content: center;
  align-items: center;
  z-index: 2;
  line-height: 1;
  white-space: nowrap;
  border: 1px solid #3B6E8F;
  background: #fff;
}
.AccessMatrix .grid-cell:hover .change-button {
  display: flex;
}
.AccessMatrix .hovered-cell {
  background-color: #EBEBEB;
}
