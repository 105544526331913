@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
@media (max-width: 991.98px) {
  .MyBids .right-block {
    margin-top: 1.5rem;
  }
}
.MyBids .nav-links {
  display: flex;
}
.MyBids .nav-links .nav-link {
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
}
.MyBids .nav-links .nav-link:hover {
  color: #3B6E8F;
}
.MyBids .nav-links .nav-link.active {
  color: #3B6E8F;
  border: 1px solid #3B6E8F;
}
@media (max-width: 991.98px) {
  .MyBids .nav-links {
    flex-direction: column;
  }
  .MyBids .nav-links .nav-link {
    margin-left: 0 !important;
  }
}
@media (max-width: 991.98px) {
  .MyBids .nav-links {
    justify-content: center;
  }
  .MyBids .search-input {
    margin-top: 1.5rem;
  }
}
