@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Browse {
  padding-bottom: 3rem;
}
@media (max-width: 991.98px) {
  .Browse .right-block {
    margin-top: 1.5rem;
  }
}
.Browse .sorting-filters {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.Browse .sorting-filters .sorting-filter {
  font-weight: 500;
  cursor: pointer;
  color: #4D4D4D;
}
.Browse .sorting-filters .sorting-filter.active {
  color: #3B6E8F;
}
.Browse .sorting-filters .divider {
  width: 2px;
  height: 1rem;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #BCBCBC;
}
