@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.RichTextEditor.RichTextEditor-is-focused label, .RichTextEditor.RichTextEditor-has-text label {
  color: #3B6E8F;
}
.RichTextEditor.RichTextEditor-is-focused .RichTextEditor-editor, .RichTextEditor.RichTextEditor-has-text .RichTextEditor-editor {
  border-color: #3B6E8F;
}
.RichTextEditor.RichTextEditor-has-error label {
  color: rgba(220, 52, 65, 0.6);
}
.RichTextEditor.RichTextEditor-has-error .RichTextEditor-editor {
  border-color: rgba(220, 52, 65, 0.6);
}
.RichTextEditor-editor {
  border: 2px solid #BCBCBC;
  padding: 0 1rem;
  transition: 0.2s;
}
.RichTextEditor-editor:focus {
  border-color: #3B6E8F;
}
.RichTextEditor-error-message {
  font-weight: bold;
  font-size: smaller;
}
