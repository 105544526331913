@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.RegularModal-modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  line-height: 1;
  color: #BCBCBC;
  cursor: pointer;
}
.RegularModal-content {
  animation: fade-in-with-translate 0.2s ease-in;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
}
.RegularModal-sm-content {
  width: 500px;
}
@media (max-width: 1199.98px) {
  .RegularModal-sm-content {
    width: 40%;
  }
}
@media (max-width: 991.98px) {
  .RegularModal-sm-content {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  .RegularModal-sm-content {
    width: 80%;
  }
}
@media (max-width: 575.98px) {
  .RegularModal-sm-content {
    width: 100%;
  }
}
.RegularModal-md-content {
  width: 600px;
}
@media (max-width: 1199.98px) {
  .RegularModal-md-content {
    width: 60%;
  }
}
@media (max-width: 991.98px) {
  .RegularModal-md-content {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  .RegularModal-md-content {
    width: 100%;
  }
}
.RegularModal-lg-content {
  width: 750px;
}
@media (max-width: 1199.98px) {
  .RegularModal-lg-content {
    width: 80%;
  }
}
@media (max-width: 991.98px) {
  .RegularModal-lg-content {
    width: 100%;
  }
}
.RegularModal-xlg-content {
  max-width: 1200px;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .RegularModal-xlg-content {
    width: 100%;
  }
}
