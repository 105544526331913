@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.AdminCompanies .popover {
  background-color: white;
  border: 1px silver solid;
  z-index: 99;
}
.AdminCompanies-content {
  position: relative;
  min-height: 4rem;
}
.AdminCompanies-content .AdminCompanies-process-status {
  padding: 2rem;
}
.AdminCompanies-content .AdminCompanies-process-status-over-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.AdminCompanies-content .AdminCompanies-process-status-over-grid .AdminCompanies-spinner {
  padding: 1rem;
  border-radius: 4px;
}
.AdminCompanies-content .AdminCompanies-grid-container table {
  min-width: 100% !important;
  table-layout: auto !important;
}
.AdminCompanies-content .AdminCompanies-grid-container table th, .AdminCompanies-content .AdminCompanies-grid-container table td {
  vertical-align: middle;
}
