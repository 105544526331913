@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.VehiclePage .breadcrumb {
  margin-top: 1.5rem;
}
.VehiclePage .breadcrumb .arrow-right-icon-container {
  height: 0.7rem;
  width: 0.7rem;
  margin: 0 1rem;
}
.VehiclePage .breadcrumb .arrow-right-icon-container .arrow-right-icon {
  fill: #4D4D4D;
}
.VehiclePage .vehicle-content {
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.VehiclePage .vehicle-content .info-card {
  background-color: rgba(246, 249, 250, 0.5);
  padding: 1.5rem;
}
.VehiclePage .vp-table-1 {
  width: 100%;
  background-color: #F3F6F7;
}
.VehiclePage .vp-table-1 th, .VehiclePage .vp-table-1 td {
  padding: 0.5rem 1rem;
}
.VehiclePage .vp-table-1 th {
  color: #3B6E8F;
}
