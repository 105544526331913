@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.FaqBlock-header {
  margin-top: 0;
  margin-bottom: 1rem;
}
.FaqBlock-header-back-button {
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  color: #3B6E8F;
  background: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.FaqBlock-header-back-button:hover {
  text-decoration: underline;
}
.FaqBlock-header-back-button:focus {
  outline: none;
  border: none;
}
.FaqBlock-header-title {
  color: #3B6E8F;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1;
}
.FaqBlock-category-cards {
  margin: -0.5rem;
}
.FaqBlock-category-card-wrapper {
  padding: 0.5rem !important;
}
