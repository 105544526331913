@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.MainNavigation {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.MainNavigation .navbar .nav-item a:hover {
  color: #3B6E8F;
}
.MainNavigation .navbar .nav-item.active a {
  color: #3B6E8F;
  font-weight: bold;
}
@media (min-width: 992px) {
  .MainNavigation .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MainNavigation .navbar .nav-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
  }
  .MainNavigation .navbar .nav-item.active::before {
    content: "";
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    border: 1px solid #3B6E8F;
  }
  .MainNavigation .navbar .nav-item.active a {
    font-weight: normal;
  }
}
