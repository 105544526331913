.AdminBidsGrid .grid-container {
  position: relative;
  min-height: 8rem;
}
.AdminBidsGrid .loading-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
}
