@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.Filter {
  border-bottom: 1px solid #EBEBEB;
  padding: 1rem 1.5rem;
}
.Filter.isCollapsable .filter-header {
  cursor: pointer;
}
.Filter .filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Filter .filter-header > * {
  display: inline-block;
  line-height: 1;
}
.Filter .filter-header .arrow-down-icon-container {
  width: 0.8rem;
  height: 0.8rem;
}
.Filter .filter-header .arrow-down-icon-container .arrow-down-icon {
  fill: #BCBCBC;
}
.Filter .filter-header .arrow-up-icon-container {
  width: 0.8rem;
  height: 0.8rem;
}
.Filter .filter-header .arrow-up-icon-container .arrow-up-icon {
  fill: #BCBCBC;
}
.Filter .filter-content-wrapper .filter-content {
  padding-top: 1rem;
}
