@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-with-scale {
  from {
    opacity: 0.01;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in-with-translate {
  from {
    opacity: 0.01;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes skeleton-animation {
  100% {
    transform: translateX(300%);
  }
}
.NavButtons-button {
  background: transparent;
  color: inherit;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
}
.NavButtons-button:not(:first-child) {
  margin-left: 1rem;
}
.NavButtons-button:hover {
  color: #3B6E8F;
  text-decoration: none;
}
.NavButtons-button:focus {
  outline: none;
  border: none;
}
.NavButtons-button.active {
  color: #3B6E8F;
  border: 1px solid #3B6E8F;
}
